<template>
    <div class="content">
<h1>Список покупок</h1>
  <AddItem
      v-bind:todosl="itemsLength"
      @add-item="addItem"
  />
  <TodoList
      v-bind:todos="todos"
      @remove-item="removeItem"
  />

      <Packed>
      </Packed>
    </div>

<NewsList/>
  </template>
<script>
import AddItem from '@/components/addItem'
import TodoList from '@/components/todoList'
import NewsList from '@/components/newsList'
import Packed from '@/components/packed'

export default {
  name: 'App',
  data() {
    return{
      todos: [
        {id: 1,
        title: "",
          compl: false
        }
      ]

    }
  },

  computed: {
    itemsLength() {
      var glenght = 0;
      if(this.todos) glenght = this.todos.length;
      return glenght;
    }
  },

  mounted() {
    let todo = localStorage.getItem("todos")
    if(todo)
    {
      this.todos = JSON.parse(todo)
    }
    else {
      this.todos = [
      ]
    }


  },

  methods:{
    removeItem(id)
    {
    this.todos = this.todos.filter(t => t.id != id)
      localStorage.setItem("todos", JSON.stringify(this.todos));
    },

    addItem(item)
    {
      if(this.todos)
      {
      this.todos.unshift(item);
      }
      else
      {
        this.todos.push(item);
      }
      localStorage.setItem("todos", JSON.stringify(this.todos));
    }
  },
  components: {
    TodoList, AddItem, NewsList, Packed
  }
}
</script>

<style>
#app {
  width: 100%;
  display: flex;
  justify-content: start;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content {
  width: 70%;
  margin-right: 30%;
}
@media (max-width: 600px) {
  .content {
    width: 100%;
  }
}
</style>
