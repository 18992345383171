<template>
<div>
<form v-on:submit.prevent="onSubmit">
  <input type="text" v-model="title">
  <button type="submit">&plus;</button>
</form>
</div>
</template>>

<script>
export default {
  props: ['todosl'],
  data(){
return {
  title: ""
}
  },
  methods:{
    onSubmit(){
        console.log('submit', this.title);
        if(this.title.trim())
        {
          const newItem = {
            id: Date.now(),
            title: this.title,
            complited: false
          }
          this.$emit('add-item', newItem);
          this.title = '';
        }
    }
  }
}

</script>

<style scoped>



</style>