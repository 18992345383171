<template>
  <div class="packet_block" ref="pack">

    <form v-on:submit.prevent="onSubmit">
      <span v-if="error" class="error">{{error}}</span>
      <span>{{str_pack}}</span><br>
       <input type="text" name="str" v-model="str" placeholder="Ввседите строку"><br>
      <input type="checkbox" id="checkbox" v-model="checkaction"><label>Распоковать</label><br>
       <button type="submit">Нажми</button><br>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return{
      str: "",
      str_pack: "",
      action: "",
    }
  },

  methods: {
     onSubmit() {
      this.action = "pack";
       if(this.checkaction) {
         this.action = "unpack";

      }
      const url="http://evsus.ru/modul/packeddtr.php";
      fetch(url, {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: 'action='+this.action+'&unpack='+this.str.replaceAll('\\', '/1;')


      })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
             this.str_pack=data.str_pack.replaceAll('/1;', '\\');
            this.error=data.error;
          });
    }


  }

}
</script>

<style scoped>

.packet_block {
  margin: 100px auto;
  max-width: 500px;
  padding: 10px 30px 30px;
  border: 1px solid #000;

}
.packet_block:before {
  content: 'запаковать / распаковать строку';
margin-bottom: 20px;
}

.packet_block input {
  margin-left: 10px;
  text-align: left;
}

span {font-size: 16px; line-height: 20px; color: green; font-weight: bold; padding: 5px;}
span.error {color: red;}
</style>