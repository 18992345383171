<template>
  <div class="newslist" ref="list">
    <button class="buttonshow" @click = "$refs.list.classList.toggle('active')">  &laquo; </button>
  <ul>
    <li v-for="item of newslist"><span>{{dataTime(item.datePub)}}</span>{{item.title}} </li>
<li class="nobulet">
  <form v-on:submit.prevent="onSubmit">
    <button type="submit" v-if="patchPrev" v-on:click="url = patchPrev"> Prev</button>
    <button type="submit" v-if="patchNext" v-on:click="url = patchNext">Next</button>

  </form>
</li>
  </ul>
  </div>
</template>

<script>
export default {

  data() {
    return{
      newslist: {},
      patchNext: "",
      patchPrev: "",
      url: ""
    }
  },

  mounted() {
    const url="https://cdnapi.smotrim.ru/api/v1/articles?includes=title:anons:datePub:tags&tags=3515&sort=date&region=MOW&plan=free";
    fetch(url)
        .then(response => response.json())
        .then(json => {
          this.newslist = json.data
          this.patchNext = json.pagination.nextUrl
          this.patchPrev = json.pagination.prevUrl
        })
  },

  methods: {
    onSubmit() {
      console.log('submit', this.url);
      const url=this.url;
      fetch(url)
          .then(response => response.json())
          .then(json => {
            this.newslist = json.data;
            this.patchNext = json.pagination.nextUrl
            this.patchPrev = json.pagination.prevUrl
          })
    },

    dataTime(str)
    {
      const hhmmss = str.split(" ");
      const hhmm = hhmmss[1].split(":");
      return hhmm[0]+':'+hhmm[1];
    }
  }

}
</script>

<style scoped>

li {
  text-align: left;
  padding: 5px;



}
li span {padding-right: 10px; font-size: 11px}
li button {margin: 5px; border-radius: 50%; padding:3px;}
.nobulet {list-style: none;}
.newslist {

  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  transition: right 0.5s ease 0s;
}
.buttonshow {
  display: none;
}
@media (max-width: 600px) {
  .newslist {
    background-color: #FFF;
    position: fixed;
    top: 0;
    right: -240px;
    width: 240px;
    transition: right 0.5s ease 0s;
    border-left: 1px solid #ccc ;
  }
  .buttonshow {
    display: block;
    border: 1px solid #f70000;
    width: 30px;
    position: absolute;
    top:20px;
    left: -30px;
  }
 .active {
   right: 0px;
   transition: right 0.5s ease 0s;
 }

  .active .buttonshow {
    content: 'rr';
    
  }

}
</style>