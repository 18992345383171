<template>

  <li><input  type="checkbox" v-bind:checked ="item.completed" v-on:change="item.completed = !item.completed, $emit('chance-item-comlead', item.id, item.completed) ">
    <h2 v-bind:class="{done: item.completed}"  v-on:click="item.completed = !item.completed, $emit('chance-item-comlead', item.id, item.completed) ">{{ item.title }}</h2>
    <button v-on:click="$emit('remove-item', item.id)">&times;</button></li>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
li{
  display: flex;
  justify-content: center;
  align-items: center;

}
li h2 {font-size: 14px;}
input {margin-right: 20px;}
button {
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  border: 1px solid #f70000;
  background-color: #FFF;
  color: #f70000;
  margin-left: 20px;
}
button:hover {
  border-bottom: 2px solid #f70000;
  border-right: 2px solid #f70000;
}

.done {
  text-decoration: line-through;
}
</style>