<template>
  <div>
    <ul v-if="todos">
      <TodoItem
          v-for="item of todos"
          v-bind:item="item"
          v-on:remove-item="removeItem"
          v-on:chance-item-comlead="chanceItemComlead"
      />
    </ul>
    <div v-else> тут поока ничего нет </div>
  </div>
</template>

<script>

import TodoItem from '@/components/todoItem';

export default {
  props: ['todos'],
  components: {
    TodoItem
  },
  methods: {
    removeItem(id){
      this.$emit('remove-item', id);
      console.log('id='+id)
    },

    chanceItemComlead(id, compl)
    {
      console.log(id, compl)
    }

  }
}

</script>